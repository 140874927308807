import React, { useState, useEffect } from 'react';
import Card from '../hero-section-card';
import { motion } from 'framer-motion'; // Import framer-motion
import backgroundImageBig from '../../assets/images/background_image_big.png';
import './index.css';

const features = [
  'One Stop Trip Management',
  'Personalize your Trip',
  'Split Bills',
  'Chat with your friends',
  'Share memories to Social',
  'Store Documents'
];

function DreamDestination() {
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);

  useEffect(() => {
    // Cycle through features every 3 seconds
    const interval = setInterval(() => {
      setCurrentFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 3000); // Change the feature every 3 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  const featureVariants = {
    hidden: { opacity: 0, y: 20 }, // Start with feature slightly lower and invisible
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }, // Animate to full opacity and normal position
    exit: { opacity: 0, y: -20, transition: { duration: 0.5 } } // Fade out and move slightly upward when exiting
  };

  return (
    <>
      {/* Mobile version */}
      <div className="overlay-mobile-container" style={{ backgroundColor: '#f8f8f8' }}>
        <div className="overlay">
          <h1 style={{ textAlign: 'center', color: 'black', padding:25 }}>
            Travel to your <br />
            Dream Destinations
          </h1>

          <div className="box">
            
              
  <motion.div
              key={currentFeatureIndex}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={featureVariants}
              className="feature-item"
            >
              <button style={{
  border: 'none',
  outline: 'none',
  fontSize: 20,
  fontWeight: 1900,
  color: '#FFFFFF',
  cursor: 'pointer',
  width: '100%', // Ensure button takes up full width
  backgroundColor: '#1C6B73', // Corrected spelling
  // borderRadius: -4,
  padding: '2px 8px', // Add some padding for a better look
  // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Add a larger shadow
}}
>
  <span style={{fontSize: 19,
  fontWeight: 600,}}>{features[currentFeatureIndex]}</span>

</button>
  </motion.div>

          </div>

          <Card />
        </div>
      </div>

      {/* Desktop version */}
      <div
        className="overlay-desktop-container"
        style={{ backgroundImage: `url(${backgroundImageBig})` }}
      >
        <div className="overlay-big">
          <div className="first">
            <h1>
              Travel and Live Your <br /> Dream Now
            </h1>

            <div className="box">
              <motion.div
                key={currentFeatureIndex}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={featureVariants}
                className="feature-item"
              >
                <button>{features[currentFeatureIndex]}</button>
              </motion.div>
            </div>

            <p>
              Travelling leaves you speechless, then turns you into a storyteller.
              <br />
              Join others in telling the story don’t listen to it.
            </p>
          </div>

          <Card />
        </div>
      </div>
    </>
  );
}

export default DreamDestination;
