import React from 'react';
import './index.css';
import footerlogo from '../../assets/images/footerlogo.svg';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/instagram.svg';
import twitter from '../../assets/images/twitter.svg';
import playstore from '../../assets/images/googleplay.svg';
import applestore from '../../assets/images/Apple store.svg';



function Footer() {
    return (
        <footer className="footer">
            <div className='footer_content'>
                <div className='footer_contents'>
                    <div className='footer_content1'>
                        <div className='footer_logo'>
                            <img src={footerlogo} alt='Travelr Logo' />
                        </div>
                        <div className='footer_para'>
                            <p>Copyright &#169; 2023</p>
                            <p>Ansmake Technologies LLP</p>
                            <p> All rights Reserved</p>
                        </div>
                        

                    </div>
                    <div className='footer_content2'>
                        <h2>Menu</h2>
                        <div  className='footer_para'>
                            <p>Destinations</p>
                            <p>Features</p>
                            <p>Testinomials</p>
                        </div>
                        

                    </div>
                    <div className='footer_content3'>
                        <h2>Information</h2>
                        <div  className='footer_para'>
                            <p>Destinations</p>
                            <p>Support</p>
                            <p>Terms & Conditions</p>
                            <p>Privacy</p>
                        </div>
                        

                    </div>
                    <div className='footer_content4'>
                        <h2>Contact Info</h2>
                        <div  className='footer_para'>
                            <p>+91-9182517283</p>
                            <p>hello@travelr.club</p>
                            <p>560066, Bengaluru,<br/> Karnataka</p>
                        </div>
                        
                    </div>
                    <div className='footer_content5'>
                        <h2>Follow us on</h2>
                        {/* <div  className='footer_para'> */}
                            <img src={facebook} />
                            <img src={instagram} />
                            <img src={twitter} />
                        {/* </div> */}
                       

                    </div>
                    {/* <div className='footer_app_download'>
                        <img src={playstore} />
                        <img src={applestore} />

                    </div> */}
                </div>
            </div>
                
            <div className='footer_app_download'>
                    <img src={playstore} />
                    <img src={applestore} />

            </div>
        </footer>
    );
}

export default Footer;
