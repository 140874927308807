// import React, { useState } from 'react';
// import logo from '../../assets/images/logo.png';
// import './index.css';
// import hamburgermenu from '../../assets/images/hamburger.svg';
// import playstore from '../../assets/images/googleplay.svg';
// import applestore from '../../assets/images/Apple store.svg';

// function Header() {
//   const [menuVisible, setMenuVisible] = useState(false);

//   const toggleMenu = () => {
//     setMenuVisible(!menuVisible);
//   };

//   return (
//     <>
//       {/* Mobile Header */}
//       <div className="mobile-header">
//         <img src={logo} alt="travelr logo" className="logo" />
//         <button className="menu-button" onClick={toggleMenu}>
//           <img src={hamburgermenu} alt="Hamburger Menu" />
//         </button>
//         {menuVisible && (
//           <div className="mobile-menu-overlay" onClick={toggleMenu}>
//             <div className="mobile-menu" onClick={(e) => e.stopPropagation()}>
//               <div className="close-button" onClick={toggleMenu}>
//                 &times;
//               </div>
//               <div className="navigation-menu">
//                 <ul>
//                   <li>
//                     <a href="#destination">Destinations</a>
//                   </li>
//                   <li>
//                     <a href="#feature">Features</a>
//                   </li>
//                   <li>
//                     <a href="#testimonials">Testimonials</a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>

//       {/* Desktop Header */}
//       <div className="desktop-header">
//         <img src={logo} alt="travelr logo" className="logo" />
//         <div className="navigation-menu">
//           <ul>
//             <li>
//               <a href="#destination">Destinations</a>
//             </li>
//             <li>
//               <a href="#feature">Features</a>
//             </li>
//             <li>
//               <a href="#testimonials">Testimonials</a>
//             </li>
//           </ul>
//         </div>

//         <div className="buttons">
//           <img src={playstore} className="play" />
//           <img src={applestore} className="apple" />
//         </div>
//       </div>
//     </>
//   );
// }

// export default Header;


import React, { useState } from 'react';
import logo from '../../assets/images/logo.png';
import './index.css';
import hamburgermenu from '../../assets/images/hamburger.svg';
import playstore from '../../assets/images/googleplay.svg';
import applestore from '../../assets/images/Apple store.svg';

function Header() {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const onGooglePlayClick = () => {
    alert("Thanks for your interest! Our Android app is coming soon. Stay tuned!");
  };

  const onAppleStoreClick = () => {
    alert("Thanks for your interest! Our iOS app is coming soon. Stay tuned!");
  };

  return (
    <>
      {/* Mobile Header */}
      <div className="mobile-header">
        <img src={logo} alt="travelr logo" className="logo" />
        <button className="menu-button" onClick={toggleMenu}>
          <img src={hamburgermenu} alt="Hamburger Menu" />
        </button>
        {menuVisible && (
          <div className="mobile-menu-overlay" onClick={toggleMenu}>
            <div className="mobile-menu" onClick={(e) => e.stopPropagation()}>
              <div className="close-button" onClick={toggleMenu}>
                &times;
              </div>
              <div className="navigation-menu">
                <ul>
                  <li><a href="#destination">Destinations</a></li>
                  <li><a href="#feature">Features</a></li>
                  <li><a href="#testimonials">About</a></li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Desktop Header */}
      <div className="desktop-header">
        <img src={logo} alt="travelr logo" className="logo" />
        <div className="navigation-menu">
          <ul>
            <li><a href="#destination">Destinations</a></li>
            <li><a href="#feature">Features</a></li>
            <li><a href="#testimonials">About</a></li>
          </ul>
        </div>

        <div className="buttons">
          <img src={playstore} className="play" alt="Google Play" onClick={onGooglePlayClick} />
          <img src={applestore} className="apple" alt="Apple Store" onClick={onAppleStoreClick} />
        </div>
      </div>
    </>
  );
}

export default Header;
