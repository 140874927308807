// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Make sure to import Bootstrap JS
// import MunnarImage from '../../assets/images/Munnar.svg';
// import OotyImage from '../../assets/images/Ooty.svg';
// import WayanadImage from '../../assets/images/Wayanad.svg';
// import GoaImage from '../../assets/images/goa.png';

// import './index.css';

// function BootstrapCarousel() {
//   return (
//     <><div className='carousel-heading'>
//       <h1 className='desktop-heading'>Popular Destinations</h1>

//       <h1 className='mobile-heading'>Top Destinations</h1>
//       {/* Bootstrap Carousel */}
//       <div id="demo" className="carousel slide" data-bs-ride="carousel">
//         <div className="carousel-indicators">
//           <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
//           <button type="button" data-bs-target="#demo" data-bs-slide-to="1" ></button>
//           <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
//           {/* <button type="button" data-bs-target="#demo" data-bs-slide-to="3"></button> */}

//         </div>

//         {/* The slideshow/carousel */}
//         <div className="carousel-inner">
//           <div className="carousel-item active">
//             <img src={MunnarImage} alt="Munnar" className="d-block w-100" style={{ height: '60vh',width:'30vh' }} />
//             <div className="carousel-caption">
//               <h3>Munnar</h3>
//             </div>
//           </div>
//           <div className="carousel-item">
//             <img src={OotyImage} alt="Ooty" className="d-block w-100" style={{ height: '60vh', width:'30vh', }} />
//             <div className="carousel-caption">
//               <h3>Ooty</h3>
//             </div>
//           </div>
//           {/* <div className="carousel-item">
//             <img src={GoaImage} alt="Ooty" className="d-block w-100" style={{ height: '49vh', width:'30vh', }} />
//             <div className="carousel-caption">
//               <h3>Goa</h3>
//             </div>
//           </div> */}
//           <div className="carousel-item">
//             <img src={WayanadImage} alt="Wayanad" className="d-block w-100" style={{ height: '60vh',width:'30vh' }} />
//             <div className="carousel-caption">
//               <h3>Wayanad</h3>
//             </div>
//           </div>
//         </div>

//         {/* Left and right controls/icons */}
//         <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
//           <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//         </button>
//         <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
//           <span className="carousel-control-next-icon" aria-hidden="true"></span>
//         </button>

        
//       </div>
//     </div>

    
//     </>
//   );
// }

// export default BootstrapCarousel;

// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css'; 
// import 'slick-carousel/slick/slick-theme.css';
// import MunnarImage from '../../assets/images/Munnar.svg';
// import OotyImage from '../../assets/images/Ooty.svg';
// import WayanadImage from '../../assets/images/Wayanad.svg';
// import GoaImage from '../../assets/images/goa.png';
// import './index.css';

// function BootstrapCarousel() {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3, 
//     slidesToScroll: 1,

//   };

//   return (
//     <div className='carousel-heading'>
//       <h1 className='desktop-heading'>Popular Destinations</h1>
//       <h1 className='mobile-heading'>Top Destinations</h1>

//       <Slider {...settings}>
        
//         <div className='carousel-item'>
//           <img src={MunnarImage} alt='Munnar' style={{ height: '60vh', width: '30vh' }} />
//           <div className='carousel-caption'>
//             <h3>Munnar</h3>
//           </div>
//         </div>
//         <div className='carousel-item'>
//           <img src={OotyImage} alt='Ooty' style={{ height: '60vh', width: '30vh' }} />
//           <div className='carousel-caption'>
//             <h3>Ooty</h3>
//           </div>
//         </div>
//         <div className='carousel-item'>
//           <img src={WayanadImage} alt='Wayanad' style={{ height: '60vh', width: '30vh' }} />
//           <div className='carousel-caption'>
//             <h3>Wayanad</h3>
//           </div>
//         </div>
//         {/* You can add more carousel items here */}
//       </Slider>
//     </div>
//   );
// }

// export default BootstrapCarousel;


// BootstrapCarousel.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import MunnarImage from '../../assets/images/Munnar.svg';
import OotyImage from '../../assets/images/Ooty.svg';
import GoaImage from '../../assets/images/goa.png';

import WayanadImage from '../../assets/images/Wayanad.svg';

import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function BootstrapCarousel() {
  const settings = {
      dots: true,
      className: "slider variable-width",
      className: "slider variable-width",

      centerMode: true,
      // infinite: true,
      centerPadding: "60px",
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      // variableWidth: true,
      // appendDots: dots => (
      //   <div
      //     style={{
      //       backgroundColor: "#ddd",
      //       borderRadius: "10px",
      //       padding: "10px"
      //     }}
      //   >
      //     <ul style={{ margin: "0px" }}> {dots} </ul>
      //   </div>
      // ),
      // customPaging: i => (
      //   <div
      //     style={{
      //       width: "30px",
      //       color: "blue",
      //       border: "1px blue solid"
      //     }}
      //   >
      //     {i + 1}
      //   </div>
      // ),
        

      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
      
  };

  const destinations = [
    { name: 'Munnar', image: MunnarImage },
    { name: 'Ooty', image: OotyImage },
    { name: 'Goa', image: GoaImage },
    // { name: 'Wayanad', image: WayanadImage },

    { name: 'Wayanad', image: WayanadImage },

  ];

  return (
    <div className='carousel-container' id="destination">
      <h1 className='desktop-heading'>Popular Destinations</h1>

      <h1 className='mobile-heading'>Top Destinations</h1>
      <Slider {...settings}>
        {destinations.map((destination, index) => (
          <div key={index} className='carousel-item' style={{ width: 100 }}>
            <img src={destination.image} alt={destination.name} className='d-block w-100' />
            <div className='carousel-caption'>
              <h3>{destination.name}</h3>
            </div>
          </div>
        ))}
      </Slider>

    </div>
  );
}

export default BootstrapCarousel;

