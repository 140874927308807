import React from 'react';
import './index.css';
import feature1Image from "../../assets/images/munnar.png";
import aitrippersonalisation from '../../assets/Interiary .gif';
import expensemonitor from '../../assets/Expence.gif';
import groupchat from '../../assets/Group Chat.gif';
import docsstorage from '../../assets/Docsstorage.gif';

const features = [
    {
      id: 'feature1',
      number: 1,
      title: 'AI Trip Personalization',
      image: aitrippersonalisation,
      imageStyle: { width: 250, height: 450 },
      specs: [
        'Revolutinizing Travel with AI, get Your Tailor-Made Itinerary Instantly',
        'Experience Unmatched Convenience and Personalization for your next journey',
      ],
    },
    {
      id: 'feature2',
      number: 2,
      title: 'Expense Monitor',
      image: expensemonitor,
      imageStyle: { width: 200, height: 200 },
      specs: [
        'Split & Settle, Effortlessly Divide Group Trip Expenses with Friends',
        'Make Group Travel Easier, Share Costs and Manage Finances Seamlessly',
      ],
    },
    {
      id: 'feature3',
      number: 3,
      title: 'Trip Group Chit chat',
      image: groupchat,
      imageStyle: { width: 240, height: 450 },
      specs: [
        'Connect and Converse, Chat with Friends and Stay Updated on Your Trip',
        'Travel Together, Virtually, Real-Time Chat and Updates for Your Group Journey',
      ],
    },
    {
      id: 'feature4',
      number: 4,
      title: "Doc's Storage",
      image: docsstorage,
      imageStyle: { width: 300, height: 300 },
      specs: [
        'Share with Trust, Securely Exchange Travel Documents with Your Friends',
        'Safeguard Your Journey: Easy and Secure Sharing of Travel Booking Details',
      ],
    },
  ];
  
  function FeaturesSection() {
    return (
        <div className="features-section" id="feature">
        <div>
            <h1 className='features_section_heading'>Our Features</h1>
        </div>
        {features.map((feature) => (
          <div className="feature" id={feature.id} key={feature.id}>
            <div className="feature-content">
              <div className="vertical_line">
                <div className="line_number">{feature.number}</div>
              </div>
              <div className="feature-container">
                <div className="feature-left">
                    <h2 className='feature_title'>{feature.title}</h2>
                    <img className='feature_image' src={feature.image} alt={feature.title} style={feature.imageStyle} />
                </div>
                <div className="feature-right">
                    <ul className="feature1_specs">
                    {feature.specs.map((spec, index) => (
                        <li key={index}>{spec}</li>
                    ))}
                    </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="feature" >
            <div className="feature-content">
                <div className='vertical_line_smiley'>
                    <div >🤗</div>
                </div>
            </div> 
        </div>  
      </div>
    );
  }
  
  export default FeaturesSection;
  