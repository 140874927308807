import React, {Fragment} from 'react';
// import logo from './path-to-your-logo.svg'; // Replace with the path to your logo file
import './header.css'; 
import Header from './components/header';
import GlassmorphicCard from './components/hero-section';
import TopDestinationsCarousel from './components/carasoul';
import FeaturesSection from './components/features/index';
import Footer from './components/footer/index';
import ChatwootWidget from './Chatwoot';

function App() {
  return (
    <div>
      <Fragment>
    <Header/>
    <GlassmorphicCard/>
    <TopDestinationsCarousel/>
    <FeaturesSection/>
    <Footer />
    </Fragment>
    </div>
  );
}

export default App;
